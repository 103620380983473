
import { Link } from "react-router-dom";
import "./Navbar.css";


export default function Navbar() {
  return (
    <nav id="nav-wrap">

      <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
      <a className="mobile-btn" href="/d" title="Hide navigation">Hide navigation</a>
      <ul id="nav" className="nav white"   >

        <li className="current"><Link className="smoothscroll" to="/">خانه</Link></li>
        <li><a className="smoothscroll" href="#about">درباره من</a></li>
        <li><a className="smoothscroll" href="#resume">رزومه</a></li>
        <li><a className="smoothscroll" href="#portfolio">نمونه کارها</a></li>
        <li><a className="smoothscroll" href="#contact">تماس</a></li>
      </ul>

    </nav>
  );
}
