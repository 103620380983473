import React from "react";
import "./Resume.css";
import "aos/dist/aos.css";

export default function Resume() {
  return (
    <>
      <section id="resume">
        <div className="row skill">
          <div
            data-aos="slide-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
            v
            className="columns header-col"
          >
            <h2>مهارت ها</h2>
            <hr />
          </div>

          <div
            className="nine columns main-col"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <p>
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
              استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله
              در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد
              نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد،
              کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان
              جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای
              طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان
              فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری
              موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد
              نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل
              دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
            </p>

            <div className="bars">
              <ul className="skills">
                <li>
                  <span className="bar-expand photoshop"></span>
                  <em>فتوشاپ</em>
                </li>
                <li>
                  <span className="bar-expand illustrator"></span>
                  <em>ایلاستریتور</em>
                </li>
                <li>
                  <span className="bar-expand wordpress"></span>
                  <em>وردپرس</em>
                </li>
                <li>
                  <span className="bar-expand css"></span>
                  <em>CSS</em>
                </li>
                <li>
                  <span className="bar-expand html5"></span>
                  <em>HTML5</em>
                </li>
                <li>
                  <span className="bar-expand jquery"></span>
                  <em>jQuery</em>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row work">
          <div
            className="columns header-col"
            data-aos="slide-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <h2>سوابق</h2>
            <hr />
          </div>

          <div
            className="nine columns main-col"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className="row item">
              <div className="twelve columns">
                <h3>شرکت جاروسازی کسری</h3>
                <p className="info">
                  طراحی گرافیک <span>&bull;</span>{" "}
                  <em className="date">سال 1399</em>
                </p>

                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                  تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                  آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
                  صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                  راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
                  حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                  موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
              </div>
            </div>

            <div className="row item">
              <div className="twelve columns">
                <h3>شرکت ایران خودرو</h3>
                <p className="info">
                  فرانت اند دولوپر <span>&bull;</span>{" "}
                  <em className="date">سال 1399 تا - حال حاضر</em>
                </p>

                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                  تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                  آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
                  صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                  راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
                  حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                  موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
