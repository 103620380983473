import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Header.css";
import Landing from "../Landing/Landing";
import InlineSVG from 'react-inlinesvg';



export default function Header({ sliderStatus = true }) {
  return (
    <header id="home">
      <img src="images/header-background.jpg" />
      <Navbar />
      {sliderStatus ? (<>
        <Landing />
        <a href="#resume"><InlineSVG
          src={`
        <svg width="40px" height="100%" className="mouse-anim" viewBox="0 0 247 390" xmlns="http://www.w3.org/2000/svg" style="
        position: absolute;
        z-index: 11111;
        top: 550px;
    "><path id="wheel" d="M123.359,79.775l0,72.843" fill="none" stroke="#fff" stroke-width="20"></path><path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" fill="none" stroke="#fff" stroke-width="20"></path></svg>
        `}
        /></a>
      </>) : (<></>)}


    </header >
  );
}
