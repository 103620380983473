import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function PortofolioDetail() {
  const [portfolio, setPortofolio] = useState([]);
  const [techs, settechs] = useState([]);
  const { PortoFolioName } = useParams();

  useEffect(() => {
    fetch(`https://api.basteweb.ir/db.json`)
      .then((res) => res.json())
      .then((result) => {
        setPortofolio(result.portofolio);
        // setPortofolio(result.portfolio.PortoFolioName);
        settechs(result.techs);
      });
  }, []);

  const portofFII = portfolio.filter((data) => data.id === PortoFolioName);
  return (
    <>
      <Header sliderStatus={false} />

      <section>
        {portofFII ? (
          <>
            {" "}
            {portofFII.map((portfolio) => (
              <div className="container" key={portfolio.index + 1}>
                <div className="row">
                  <div className="portofolio-box">
                    <h1>{portfolio.title}</h1>
                    <hr></hr>
                    <img alt="" src={`/${portfolio.img}`} />
                    <p>{portfolio.body}</p>

                    <a href="d">{portfolio.tech} </a>

                    <span> تاریخ انجام پروژه : {portfolio.date} </span>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p>d</p>
        )}
      </section>
      <Footer />
    </>
  );
}
